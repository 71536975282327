<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">{{ i18n('attendees.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    ></TableFilter>
    <!-- END: HTML Table Filter -->

    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-2 sm:p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end">
        <button type="button" class="btn bg-theme-31 text-white sm:w-auto flex items-center gap-2" @click="showModal()">
          <PlusIcon class="w-4 h-4" /> {{ i18n('common.join') }}
        </button>
      </div>

      <div ref="table" class="relative">
        <loading-spinner type="half-circle" :duration="1000" :size="40" color="#4469A8" :loading="loading" />

        <div class="overflow-x-auto">
          <table id="eventAttendeesTable" ref="tableRef" class="table table-report sm:mt-2">
            <thead>
              <tr>
                <th class="whitespace-nowrap" id="">#</th>
                <th v-for="col in columns" :key="col.name" class="text-center whitespace-nowrap">
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>
            <tbody v-if="rows.length">
              <tr v-for="(row, index) in rows" :key="index" class="intro-x">
                <td class="">
                  <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">{{ rowIndex(index) }}</div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'name') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'phoneNumber') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterInteger(row, 'guestsCount') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterInteger(row, 'kidsCount') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDate(row, 'createdAt') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterTime(row, 'createdAt') }}
                  </div>
                </td>
                <td class="table-report__action w-56" id="ignore-6">
                  <div class="flex justify-center items-center gap-3 text-theme-10">
                    <button v-if="!row.isAttended"
                      type="button"
                      class="text-green-600 flex items-center gap-2 whitespace-nowrap"
                      @click="showConfirmModal(row.id)"
                    >
                      <Tippy tag="div" class="report-box__indicator flex items-center gap-2" :content="i18n('attendees.confirm.button')">
                        <CheckCircleIcon class="w-4 h-4" /> {{ i18n('common.confirm') }}
                      </Tippy>
                    </button>
                    <div class="flex justify-center items-center gap-1 text-theme-10" v-else>
                      <CheckSquareIcon class="w-4 h-4" /> {{ i18n('attendees.fields.isAttended') }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-empty-page v-if="!rows.length && !loading"
            :label="i18n('empty.attendees')"
            label-class="text-lg font-medium"
            icon-size="4x"
            class="box w-full py-64"
          ></app-empty-page>
        </div>
        <Pagination v-if="rows.length"
          :page="currentPage"
          :pageSize="pagination.limit"
          :rowsNumber="pagination.total"
          :showSizeChanger="true"
          :disabled="!rows.length"
          position="center"
          @next-page="doNextPage"
          @prev-change="doPrevPage"
          @size-change="doChangePaginationPageSize"
        ></Pagination>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <join-event-modal ref="modal" :id="id"></join-event-modal>
    <ConfirmModal
      id="confirm-attendance-modal"
      title=""
      :message="isRTL ? 'هل تريد حقًا تأكيد حضور هذا المستخدم' : 'Do you really want to confirm attendance for this user'"
      item=""
      btnOkStyle="background: rgba(22, 163, 74, 1); border-color: rgba(22, 163, 74, 1);"
      @submit="doConfirmAttendance()"
    >
      <template v-slot:icon>
        <CheckCircleIcon class="w-16 h-16 text-green-600 mt-3" />
      </template>
    </ConfirmModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineComponent, ref, onMounted } from 'vue'
import feather from 'feather-icons'
import { GenericModel } from '@/shared/model/generic-model'
import JoinEventModal from '@/views/event/modal/join-event-modal.vue'
import ConfirmModal from '@/components/modals/confirm-modal.vue'

export default defineComponent({
  name: 'event-list-attendees',
  props: {
    id: {
      type: String
    }
  },
  components: {
    ConfirmModal,
    [JoinEventModal.name]: JoinEventModal
  },
  setup() {
    const tableRef = ref()
    const tabulator = ref()
    const selected = ref([])
    const selectAll = ref(false)

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      selected,
      selectAll,
      tableRef
    }
  },
  data() {
    return {
      selectedId: null,
      filterInput: [],
      columns: [
        {
          name: 'name',
          field: 'name',
          label: 'attendees.fields.name',
          align: 'center'
          // required: true,
        },
        {
          name: 'phoneNumber',
          field: 'phoneNumber',
          label: 'attendees.fields.phoneNumber',
          align: 'center'
          // required: true,
        },
        {
          name: 'guestsCount',
          field: 'guestsCount',
          label: 'attendees.fields.guestsCount',
          align: 'center'
          // required: true,
        },
        {
          name: 'kidsCount',
          field: 'kidsCount',
          label: 'attendees.fields.kidsCount',
          align: 'center'
          // required: true,
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'attendees.fields.createdAt',
          align: 'center'
          // required: true,
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'attendees.fields.time',
          align: 'center'
          // required: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      rows: 'attendee/list/rows',
      pagination: 'attendee/list/pagination',
      listLoading: 'attendee/list/loading',
      saveLoading: 'attendee/form/saveLoading'
    }),
    loading() {
      return this.listLoading || this.saveLoading
    },
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return (index) => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    },
    filterFields() {
      return [
        { label: this.i18n('attendees.fields.name'), value: 'name', type: 'text' },
        { label: this.i18n('attendees.fields.phoneNumber'), value: 'phoneNumber', type: 'text' }
        // { label: this.i18n('attendees.fields.createdAt'), value: 'createdAt', type: 'date' }
      ]
    }
  },
  created() {
    document.title = this.i18n('app.title') + ' | ' + this.i18n('routes.events') + ' | ' + this.i18n('routes.attendees')
  },
  watch: {
    isRTL() {
      document.title = this.i18n('app.title') + ' | ' + this.i18n('routes.events') + ' | ' + this.i18n('routes.attendees')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
    // this.doMountTable(this.$refs.table)
  },
  methods: {
    ...mapActions({
      doFetch: 'attendee/list/doFetch',
      doFetchNextPage: 'attendee/list/doFetchNextPage',
      doFetchPreviousPage: 'attendee/list/doFetchPreviousPage',
      doMountTable: 'attendee/list/doMountTable',
      doChangeSort: 'attendee/list/doChangeSort',
      doChangePaginationCurrentPage: 'attendee/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'attendee/list/doChangePaginationPageSize',
      doConfirm: 'attendee/form/doConfirm'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterTime(row, fieldName) {
      return GenericModel.presenterTime(row, fieldName)
    },
    presenterDateTime(row, fieldName) {
      return GenericModel.presenterDateTime(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options
      const val = options.filter(el => el.id == row[fieldName])
      return val.length ? val[0].label : 'ـــ'
    },
    showModal() {
      cash('#join-event-modal').modal('show')
    },
    showConfirmModal(id) {
      this.selectedId = id
      cash('#confirm-attendance-modal').modal('show')
    },
    async doConfirmAttendance() {
      await this.doConfirm({
        eventId: this.id,
        joinerId: this.selectedId
      })
      this.selectedId = null
    },
    async doFilter(filter) {
      this.filterInput = []
      if (!filter || !filter.value) return
      if (Array.isArray(filter)) {
        this.filterInput.push(...filter)
      } else {
        this.filterInput.push(filter)
      }
      await this.doFetchFirstPage()
    },
    async doResetFilter() {
      this.filterInput = []
      await this.doFetchFirstPage()
    },
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        eventId: this.id,
        filter: this.filterInput,
        pagination: {
          firstPage: true,
          limit: 20,
          action: 'next',
          orderBy: 'createdAt',
          sortType: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
